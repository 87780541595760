<template>
  <el-card>
    <div class="vg_mb_8">
      退样日期
      <el-date-picker
        class="vg_ml_8 vg_mr_8 topDateRangeSearch"
        size="small"
        v-model="dateRange"
        value-format="timestamp"
        type="daterange"
        range-separator="至"
        start-placeholder="退样开始日期"
        end-placeholder="退样结束日期"
      />
      <el-button type="primary" size="small" class="vd_export" @click="getTableData()">查询</el-button>
      <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()">刷新</el-button>
    </div>
    <DynamicUTable
      ref="multiTable"
      :tableData="tableData"
      :columns="tableProperties"
      v-loading="loading"
      :need-pagination="false"
      :need-check-box="false"
      :need-search="true"
      @getTableData="getTableData"
    >
      <template v-slot:operation="scope">
        <el-link
          class="vg_mr_8 vg_cursor"
          size="mini"
          type="primary"
          @click="
            jump('/fequ_edit', {
              key: $UrlEncode.encode(
                JSON.stringify({
                  perm_id: 116,
                  form_id: scope.row.form_id
                })
              )
            })
          "
          >查看
        </el-link>
        <el-link class="vg_cursor" size="mini" type="danger" @click="refusalToReturnSamples(scope.row)">删除</el-link>
      </template>
    </DynamicUTable>
    <el-dialog title="操作" :visible.sync="dialogVisible" width="60%" :modal-append-to-body="false">
      <DynamicUTable ref="dialogTable" :columns="dialogTableProperties" :need-check-box="false" :need-pagination="false" :tableData="dialogTableData">
        <template v-slot:fequ_no="scope">
          <el-link size="mini" type="primary" class="vg_cursor" @click="jumpFequ(scope.row)">{{ scope.row.fequ_no }}</el-link>
        </template>
        <template v-slot:operation="scope">
          <el-button size="mini" type="warning" @click="confirmSampleReturn(scope.row)">确认退样</el-button>
        </template>
      </DynamicUTable>
    </el-dialog>
  </el-card>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { getDate, getDateNoTime } from '@assets/js/dateUtils';
import { requAPI } from '@api/modules/requ';
import { cloneDeep } from 'lodash';
import { fequAPI } from '@api/modules/fequ';
import $UrlEncode from '@assets/js/UrlEncode';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'requPush9004',
  computed: {
    $UrlEncode() {
      return $UrlEncode;
    }
  },
  components: { DynamicUTable },
  data() {
    return {
      dateRange: [],
      tableData: [],
      loading: true,
      tableProperties: [
        ////类型0退样1取消打样
        {
          label: '类型',
          prop: 'requestpush_type',
          itemType: 'select',
          options: [
            { value: 0, label: '退样' },
            { value: 1, label: '取消打样' }
          ],
          input: true,
          sortable: false,
          widthAuto: true,
          formatter: val => (val === 0 ? '退样' : '取消打样')
        },
        {
          label: '提交时间',
          prop: 'create_time',
          itemType: 'input',
          input: false,
          sortable: false,
          widthAuto: true,
          formatter: val => getDate(val, false)
        },
        { label: '委托单号', prop: 'requ_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '我司货号', prop: 'prod_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '更改号', prop: 'prod_suffix', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '产品名称', prop: 'prod_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '操作数量', prop: 'business_back_total', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '退样/取消打样原因', prop: 'business_back_reason', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '操作', prop: 'operation', itemType: 'input', input: false, sortable: false, widthAuto: true }
      ],
      dialogVisible: false,
      dialogTableData: [],
      dialogTableProperties: [
        { label: '工厂打样单号', prop: 'fequ_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '所属打样间', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
        {
          label: '要求交样日期',
          prop: 'requ_smpl_pedate',
          itemType: 'input',
          input: true,
          sortable: false,
          widthAuto: true,
          formatter: val => getDateNoTime(val, true)
        },
        { label: '操作', prop: 'operation', itemType: 'input', input: true, sortable: false, widthAuto: true }
      ],
      requestpush_id: null,
      business_back_reason: null
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loading = true;
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      let [startTime, endTime] = this.dateRange || [];
      searchForm.startTime = startTime ?? null;
      searchForm.endTime = endTime ?? null;
      searchForm.requ_taker = this.$cookies.get('userInfo').stff_id;
      requAPI.getRequestpush(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.loading = false;
      });
    },
    buttonRefresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    showDialog({ requ_smpl_id, requestpush_id, business_back_reason }) {
      this.requestpush_id = requestpush_id;
      this.business_back_reason = business_back_reason;
      fequAPI.getFequSmplList({ requ_smpl_id }).then(({ data }) => {
        this.dialogTableData = data.list;
        this.dialogVisible = true;
      });
    },
    refusalToReturnSamples(row) {
      requAPI.deleteRequestpush(row).then(({ data }) => {
        this.$message.success('操作成功!');
        this.getTableData();
      });
    },
    confirmSampleReturn(row) {
      row.requestpush_id = this.requestpush_id;
      row.business_back_reason = this.business_back_reason;
      fequAPI.editFequSmplPush(row).then(({ data }) => {
        this.dialogVisible = false;
        this.getTableData();
        this.jumpFequ(row);
      });
    },
    jumpFequ(row) {
      this.jump('/fequ_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: 116,
            form_id: row.fequ_id
          })
        )
      });
    }
  }
};
</script>

<style></style>
