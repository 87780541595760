var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',[_c('div',{staticClass:"vg_mb_8"},[_vm._v(" 退样日期 "),_c('el-date-picker',{staticClass:"vg_ml_8 vg_mr_8 topDateRangeSearch",attrs:{"size":"small","value-format":"timestamp","type":"daterange","range-separator":"至","start-placeholder":"退样开始日期","end-placeholder":"退样结束日期"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('el-button',{staticClass:"vd_export",attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.getTableData()}}},[_vm._v("查询")]),_c('el-button',{staticClass:"vd_export",attrs:{"type":"info","size":"small","icon":"el-icon-refresh-right"},on:{"click":function($event){return _vm.buttonRefresh()}}},[_vm._v("刷新")])],1),_c('DynamicUTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"multiTable",attrs:{"tableData":_vm.tableData,"columns":_vm.tableProperties,"need-pagination":false,"need-check-box":false,"need-search":true},on:{"getTableData":_vm.getTableData},scopedSlots:_vm._u([{key:"operation",fn:function(scope){return [_c('el-link',{staticClass:"vg_mr_8 vg_cursor",attrs:{"size":"mini","type":"primary"},on:{"click":function($event){_vm.jump('/fequ_edit', {
            key: _vm.$UrlEncode.encode(
              JSON.stringify({
                perm_id: 116,
                form_id: scope.row.form_id
              })
            )
          })}}},[_vm._v("查看 ")]),_c('el-link',{staticClass:"vg_cursor",attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.refusalToReturnSamples(scope.row)}}},[_vm._v("删除")])]}}])}),_c('el-dialog',{attrs:{"title":"操作","visible":_vm.dialogVisible,"width":"60%","modal-append-to-body":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('DynamicUTable',{ref:"dialogTable",attrs:{"columns":_vm.dialogTableProperties,"need-check-box":false,"need-pagination":false,"tableData":_vm.dialogTableData},scopedSlots:_vm._u([{key:"fequ_no",fn:function(scope){return [_c('el-link',{staticClass:"vg_cursor",attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.jumpFequ(scope.row)}}},[_vm._v(_vm._s(scope.row.fequ_no))])]}},{key:"operation",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","type":"warning"},on:{"click":function($event){return _vm.confirmSampleReturn(scope.row)}}},[_vm._v("确认退样")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }